<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row d-flex justify-content-between px-2">
              <h2 class="mb-0">List All Practice Hololens</h2>
              <div class="wrapper-nav">
                <base-button
                  class="mr-3"
                  type="outline-vr"
                  size="lg-vr"
                  @click="getListHololensAgain"
                >Refresh Page</base-button>
                <router-link
                  :to="{ name: 'listPracticeHistory' }"
                  class="btn btn-brand-ar-vr"
                >History</router-link>
              </div>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
              <div class="wrapper-select">
                <span>Show</span>
                <div class="select-row">
                  <el-select
                    class="select-primary pagination-select"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <span>Row</span>
              </div>
              <div class="form-search">
                <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
              </div>
            </div>
            <loading-panel v-if="loading.refetch_data" />
            <el-table
              v-else
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
              <el-table-column prop="status" label="Status" width="120">
                <div slot-scope="{$index, row}" class="d-flex">
                  <span :class="isDone(row.status)">{{ row.status }}</span>
                </div>
              </el-table-column>
              <el-table-column min-width="250px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                  <base-button
                    @click.native="handlePopStream(row)"
                    type="detail-vr"
                    size="sm"
                    :disabled="isStream(row.status)"
                  >
                    <span>Stream Video</span>
                  </base-button>
                  <base-button
                    @click.native="handlePopScore(row)"
                    type="success-vr"
                    size="sm"
                    :disabled="isScore(row.status)"
                  >
                    <span>Practice Score</span>
                  </base-button>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span
                  v-if="selectedRows.length"
                >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
    <modal :show.sync="modals.showModalStream" size="sm" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Quality</h6>
      </template>
      <span class="body-modal">
        <div>
          <span v-for="list in listQuality" :key="list.key" class="list-quality">
            <input
              :id="list.value"
              type="checkbox"
              class="mb-1"
              :value="list.value"
              :checked="list.checked"
              @change="checkQuality(list.key)"
            />
            <label class="label-quality" :for="list.value">{{ list.name }}</label>
          </span>
        </div>
      </span>
      <!-- <template slot="footer"> -->
      <div class="text-center">
        <base-button type="delete-vr" class="mt-2" @click="streamNow">Stream Now</base-button>
      </div>
      <!-- </template> -->
    </modal>
    <modal :show.sync="modals.showModalScore" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Practice Score</h6>
      </template>
      <span class="body-modal">
        <validation-observer v-slot="{ handleSubmit }" ref="formPostScore">
          <form role="form" @submit.prevent="handleSubmit(handlePostScore)">
            <div class="row">
              <div class="col-5">
                <base-input
                  label="Score"
                  class="mb-3"
                  name="Score"
                  type="number"
                  placeholder="0"
                  v-model="scoring.score"
                  :rules="{ required: true, integer: true, min_value: 0, max_value: 100 }"
                ></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <base-input
                  label="Description"
                  class="mb-3"
                  name="Description"
                  :rules="{ required: true }"
                >
                  <textarea
                    class="form-control"
                    placeholder="write description here ..."
                    v-model="scoring.description"
                  ></textarea>
                </base-input>
              </div>
            </div>
            <div class="text-right">
              <base-button
                type="outline-vr"
                class="my-2"
                @click="modals.showModalScore = false"
              >Cancel</base-button>
              <base-button type="success-vr" class="my-2" native-type="submit">Input</base-button>
            </div>
          </form>
        </validation-observer>
      </span>
      <!-- <template slot="footer"> -->
      <!-- </template> -->
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import listPracticeMixin from './Mixins/listPracticeMixin'
import { getListPractice, insertNilaiHololens } from '@/services/hololens'
import LoadingPanel from '@/components/LoadingPanel.vue';
import Fuse from "fuse.js";
export default {
  mixins: [listPracticeMixin],
  name: "ListCourses",
  components: {
    BasePagination,
    DashboardNavbar,
    LoadingPanel,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      propsToSearch: ['date', 'user.name', 'user.npaidi', 'course.name'],
      tableColumns: [
        {
          prop: 'date',
          label: 'Date',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'user.name',
          label: 'Student Name',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'user.npaidi',
          label: 'NPA IDI',
          minWidth: 120,
          sortable: true
        },
        {
          prop: 'course.name',
          label: 'Course',
          minWidth: 200,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      routes: [
        {
          name: "listPractice",
          alias: "LIST PRACTICE HOLOLENS"
        }
      ],
      selectedStream: null,
      selectedToScoringID: null,
      scoring: {
        score: null,
        description: null
      },
      listQuality: [
        { key: 1, name: "High", value: 'live_high', checked: false },
        { key: 2, name: "Medium", value: 'live_med', checked: false },
        { key: 3, name: "Low", value: 'live_low', checked: false },
      ],
      modals: {
        showModalStream: false,
        showModalScore: false
      },
      loading: {
        refetch_data: false
      }

    };
  },
  methods: {
    isDone(val) {
      if (val === "On Progress") {
        return "status-pratice"
      } else if (val === "Done") {
        return "status-done"
      }
    },
    isStream(val) {
      if (val === "On Progress") {
        return false
      } else if (val === "Done") {
        return true
      }
    },
    isScore(val) {
      if (val === "On Progress") {
        return true
      } else if (val === "Done") {
        return false
      }
    },
    handlePopStream(row) {
      for (const box in this.listQuality) {
        this.listQuality[box].checked = false
      }
      this.selectedStream = row
      this.modals.showModalStream = true
    },
    handlePopScore(row) {
      this.selectedToScoringID = row.holoId
      this.modals.showModalScore = true
      this.scoring = {
        score: null,
        description: null
      }
    },
    async handlePostScore() {
      try {
        const payload = {
          holoId: this.selectedToScoringID,
          parameter: "",
          nilai: +this.scoring.score,
          description: this.scoring.description
        }
        const { data } = await insertNilaiHololens(payload)
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        this.modals.showModalScore = false
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "successfully input score"
        });
      } catch (error) {
        this.modals.showModalScore = false
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else if (error === "EMPTY_RESULT") {
          this.tableData = []
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "successfully input score"
          });
        }
        else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    checkQuality(val) {
      for (const box in this.listQuality) {
        if (!this.listQuality[val - 1].checked) {
          this.listQuality[box].checked = false
        }
      }
      this.listQuality[val - 1].checked = !this.listQuality[val - 1].checked
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getListHololensAgain() {
      try {
        this.loading.refetch_data = true
        const { data } = await getListPractice(0)
        this.loading.refetch_data = false
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "successfully retrieve data"
        });
      } catch (error) {
        this.loading.refetch_data = false
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else if (error === "EMPTY_RESULT") {
          this.tableData = []
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async getListHololens() {
      try {
        const { data } = await getListPractice(0)
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    streamNow() {
      const selectedQuality = this.listQuality.find(({ checked }) => checked)
      if (selectedQuality) {
        window.open(`https://${this.selectedStream.ip}/api/holographic/stream/${selectedQuality.value}.mp4?holo=true&pv=true&mic=true&loopback=true&RenderFromCamera=true`)
        this.selectedStream = null
        this.modals.showModalStream = false
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Select quality"
        });
      }
    }
  },
  mounted() {
    this.getListHololens()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.wrapper-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}
.status-pratice {
  color: $blue-ar-vr;
}

.status-done {
  color: $status-pass-ar-vr;
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .list-quality {
    display: block;
  }
  .label-quality {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #222f3e;
  }
}
</style>