import { api } from "./axios";

const getListInvoice = () => {
  return api.get(`v1/invoice`);
};

const postWithdraw = data => {
  return api.post(`v1/invoice/withdraw`, data);
};

const getDetailIncome = id => {
  return api.get(`v1/invoice/detail?id=${id}`);
};
const generateInvoicePDF = id => {
  return api.get(`v1/invoice/pdf?id=${id}`, {
    responseType: "blob"
  });
};

export { getListInvoice, getDetailIncome, postWithdraw, generateInvoicePDF };
