<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <div class="row d-flex justify-content-between px-2">
              <h2 class="mb-0">{{ titlePeriod }}</h2>
              <base-button
                type="outline-vr"
                size="lg-vr"
                @click="pushToWithdraw"
              >Back to List Withdraw</base-button>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
              <div class="wrapper-select">
                <span>Show</span>
                <div class="select-row">
                  <el-select
                    class="select-primary pagination-select"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <span>Row</span>
              </div>
              <div class="form-search">
                <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
              </div>
            </div>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
              <el-table-column prop="unitPrice" label="Price" min-width="150px">
                <div slot-scope="{$index, row}" class="d-flex">
                  <span>{{ 'Rp. ' + row.unitPrice }}</span>
                </div>
              </el-table-column>
              <el-table-column prop="totalAmount" label="Total Amount" min-width="150px">
                <div slot-scope="{$index, row}" class="d-flex">
                  <span>{{ 'Rp. ' + row.totalAmount }}</span>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span
                  v-if="selectedRows.length"
                >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import listInvoiceMixin from './Mixins/listInvoiceMixin'
import { getDetailIncome } from '@/services/invoice'
import Fuse from "fuse.js";
export default {
  mixins: [listInvoiceMixin],
  name: "ListInvoice",
  components: {
    BasePagination,
    DashboardNavbar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      propsToSearch: ['title', 'newParticipant', 'totalAmount'],
      tableColumns: [
        {
          prop: 'title',
          label: 'Course Title',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'newParticipant',
          label: 'Total New Participant',
          minWidth: 150,
          sortable: true
        },
        // {
        //   prop: 'totalAmount',
        //   label: 'Total Amount',
        //   minWidth: 150,
        //   sortable: true
        // },
      ],
      tableData: [],
      titlePeriod: '',
      selectedRows: [],
      routes: [
        {
          name: "listInvoice",
          alias: "LIST INVOICE WITHDRAWAL"
        },
        {
          name: "listdetailIncome",
          alias: "DETAIL INCOME"
        }
      ],
    };
  },
  methods: {
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    pushToWithdraw() {
      this.$router.push({ name: 'listInvoice' })
    },
    async detailIncome() {
      try {
        const { data } = await getDetailIncome(this.$route.params.id)
        this.tableData = data.detail
        this.titlePeriod = data.period
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
  },
  mounted() {
    this.detailIncome()
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";

.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}
</style>